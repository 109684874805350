@import "./_color";
.bd {
  &-invalid-input {
    border: 1px solid red;
  }
  &-border-children-cell-table {
    border: 1px solid $border_children_cell_table;
  }
  &-bot {
    &-header-dropdown {
      border-bottom: 1px solid $border_bottom_header_dropdown;
    }

    &-page-title {
      border-bottom: 1px solid $gray3;
    }
    &-select-list-item {
      border-bottom: 1px solid $border_bottom_select_list_item;
    }
    &-header-table {
      border-bottom: 1px solid #707070;
    }
    &-radius-11 {
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
    }
  }
  &-left {
    &-select-list-icon {
      border-left: 1px solid $border_input;
    }
    &-header-table {
      border-left: 1px solid #707070;
    }
    &-header-history-table {
      border-left: 2px solid #20458f;
    }
  }
  &-right {
    &-select-list-icon {
      border-right: 1px solid $border_input;
    }
    &-header-table {
      border-right: 1px solid #707070;
    }
    &-cell-table {
      border-right: 1px solid $border_table;
    }
    &-header-history-table {
      border-right: 2px solid #20458f;
    }
  }
}

.invalid-input {
  outline: none;
  border: 2px solid rgb(242, 109, 109) !important;
}

.ol-input {
  outline-width: 1px;
  outline-color: rgb(19, 139, 244);
}

.txt-required {
  position: relative;
  &::after {
    content: "*";
    color: red;
    position: absolute;
    right: -8px;
    font-size: 16px;
  }
}
.require-filed::after {
  content: "*";
  color: $red;
}

.ok-btn {
  background-color: #f25524;
}

.cancel-btn {
  border: 1px solid #f25524;
  color: #f25524;
}

.custom-input {
  border: 1px solid $border_input;
  border-radius: 7px;
  width: 100%;
  //height: 27px;
  padding-left: 7px;
  font-size: 14px;
}

.custom-input ~ .message {
  font-size: 12px !important;
}
.custom-input-txt-area {
  border: 1px solid $border_input;
  border-radius: 7px;
  resize: none;
  padding: 10px;
  font-size: 14px;
}

.bd-2-red {
  border: 2px solid #f25524;
}

.disable-all-event {
  pointer-events: none;
}

.layout-border-radius {
  border-radius: 8px;
}

.box-input {
  .ant-select-selector {
    height: 22px !important;
    .ant-select-selection-placeholder {
      line-height: 20px;
    }
  }
  .ant-picker {
    height: 24px !important;
  }
}
