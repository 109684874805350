@import './color';

@mixin configTable {
  .assignmentTable {
    tbody {
      .ant-table-cell {
        text-align: center !important;
        padding-left: 20px;

        &.text-left {
          text-align: left !important;
        }
      }
    }

    thead {
      height: 45px;
      .ant-table-cell {
        text-align: center !important;
        font-size: 16px;
        padding: 0 !important;
      }

      tr {
        th {
          border-bottom: 1px solid #707070 !important;
          border-left: 1px solid #707070 !important;

          &:first-child {
            border-left: none !important;
          }
        }
      }
    }
  }
}

@mixin permissionTable {
  .permissionTable {
    .ant-table-cell {
      background: #fefefe;
    }

    .col {
      font-size: 14px;
    }

    .ant-table-cell {
      padding: 0 !important;
      text-align: center !important;

      &[scope='colgroup'] {
        border-right: 1px solid #707070;

        &:last-child {
          border-right: none;
        }
      }

      &[scope='col'] {
        border-right: 1px solid #707070;
      }
    }

    tr {
      .ant-table-cell.col {
        border-right: 1px solid #707070;
        &:last-child {
          border-right: none;
        }
      }
    }

    .ant-table-cell-fix-left {
      border-right: 1px solid #707070;
    }

    thead {
      tr {
        height: 50px;

        &:first-child th {
          border-bottom: 1px solid #707070 !important;
        }
      }
    }

    &.vues {
      tr {
        .ant-table-cell:first-child {
          border-right: 1px solid #707070;
        }
      }
    }
  }
}

@mixin saveButton {
  .btn-submit-modal {
    padding: 8px 20px;
    color: white;
    background-color: $green;
    height: fit-content;
    border-radius: 7px;
    border: none;
    cursor: pointer;

    &.disabled {
      background-color: $gray3;
      cursor: not-allowed;

      &:hover {
        color: white !important;
      }
    }
  }
}
