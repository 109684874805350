@import '../../../assets/scss/color';

//nav
.header .ant-input-affix-wrapper > input.ant-input {
  background-color: #ecf8ff;
}

.nav-select .ant-select-selector {
  background-color: $primary !important;
  width: 100% !important;
}

.nav-select .ant-select-selection-placeholder {
  color: white !important;
  font-style: normal !important;
}

.menu-item {
  position: relative;
  user-select: none;
  cursor: pointer;
}

.menu-item:hover,
.menu-item.active {
  text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
}

.menu-item.active::after {
  height: 2px;
  width: 20%;
  background-color: #f09637;
  position: absolute;
  right: 0;
  bottom: -5px;
  content: '';
}

.nav-dropdown {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  &.icon-config {
    background: url('../../../assets/images/config.png') center no-repeat;
  }
  &.icon-logout {
    background: url('../../../assets/images/logout.png') center no-repeat;
  }
}

.icon-avatar {
  background: url('../../../assets/images/user.png') center no-repeat;
  width: 1.3rem;
  height: 1.3rem;
  display: block;
  border-radius: 100%;
  border: 1px solid $primary;
}
