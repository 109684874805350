@import '../../../assets/scss/color';

.ant-table-wrapper .ant-table-tbody > tr > th {
  border-bottom: 0px !important;
}

.table-wrapper {
  width: calc(100% - 80px);
}

.table-wrapper table {
  table-layout: fixed !important;
}

.table-wrapper img,
.table-fixed img {
  height: 23px;
  display: block;
}

// .table-wrapper ::-webkit-scrollbar {
//   height: 8px;
//   width: 8px;
// }

.table-wrapper ::-webkit-scrollbar-track {
  background-color: #dbdbdb;
  border: solid 20px transparent;
}

.table-wrapper ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $secondary;
  border: solid 20px transparent;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0;
}

.table-wrapper.ant-table-wrapper tbody > tr > td,
.table-fixed.ant-table-wrapper tbody > tr > td {
  padding: 6px 16px;
  border-top: 1px solid $gray3;
}

.table-wrapper.ant-table-wrapper thead > tr > th,
.table-fixed.ant-table-wrapper thead > tr > th {
  padding: 7px 16px;
}

.table-fixed .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-fixed .ant-checkbox {
  top: 0;
}

.custom-pagination.ant-pagination {
  background-color: $primary;
  margin: 20px 40px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 25px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.custom-pagination.ant-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link {
  color: white;
}

.custom-pagination.ant-pagination .ant-pagination-item-active {
  background-color: transparent;
  border-color: transparent;
  :hover {
    color: white !important;
    border-color: transparent !important;
  }
}

.custom-pagination.ant-pagination .ant-pagination-item-active a {
  color: #54bbff !important;
}
.custom-pagination.ant-pagination .ant-pagination-item a {
  color: #fff;
}

.custom-pagination.ant-pagination .ant-pagination-prev button {
  color: white;
}

.custom-pagination.ant-pagination .ant-pagination-options {
  order: -1;
  &::before {
    content: 'Lignes par page: ';
  }
}

.custom-pagination.ant-pagination .ant-pagination-total-text {
  position: relative;
}

.custom-pagination .ant-select-selector {
  background-color: transparent !important;
  color: white !important;
  border-color: transparent !important;
}

.custom-pagination.ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: white;
  padding: 0 20px;
  &::before {
    content: 'SUIVANT';
    padding-right: 10px;
  }
}

.custom-pagination.ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: white;
  padding: 0 20px;
  &::after {
    content: 'PRECEDENT';
    padding-left: 10px;
  }
}

.custom-pagination.ant-pagination .ant-pagination-item-active {
  font-weight: bold;
}

.custom-pagination .ant-select .ant-select-arrow {
  color: white;
  margin-right: 10px;
}

.custom-pagination .ant-select-selection-item {
  font-weight: bold;
}

.ant-table-wrapper-right .ant-table-content-wrapper {
  box-shadow: none !important;
}

.first-column {
  padding-left: 30px !important;
}

.ant-table-wrapper .ant-table-cell-fix-left {
  background: none;
}

.attendu
  .ant-table-row.table-row-child
  .ant-table-cell-fix-left.ant-table-cell-with-append {
  border: none !important;
}

.attendu
  .ant-table-row.table-row-child
  .ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  border-top: none !important;
  border-bottom: none !important;
}

.attendu .ant-table-row {
  height: 35px;
  color: #808080;
  font-weight: 500;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &:hover {
    background-color: #8b9fc7 !important;
    .hover-text,
    div {
      color: white !important;
    }
    > .ant-table-cell-fix-left {
      background-color: #8b9fc7 !important;
      color: white !important;
    }
    .anticon {
      color: #fff !important;
    }
  }
  .ant-table-cell {
    padding: 6px 16px 6px 16px !important;
    border-top: 1px solid #b7b7b7;
    .anticon {
      margin-left: 5px;
      color: #808080;
    }
  }
}

.attendu .ant-table-column-sorters .anticon {
  margin-left: 5px;
  color: #808080;
}

.attendu .table-row-main-even {
  background-color: #edf8ff !important;
  > .ant-table-cell-fix-left {
    background-color: #edf8ff !important;
  }
}

.attendu .table-row-main-odd {
  background-color: #f8fcff !important;

  > .ant-table-cell-fix-left {
    background-color: #f8fcff !important;
  }
}

.attendu .table-row-child {
  background-color: #fff !important;
  > .ant-table-cell-fix-left {
    background-color: #fff !important;
  }
}

.attendu .ant-table-thead .ant-table-cell {
  background-color: #fbfbfb;
}

.attendu {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    border-top: 1px solid $gray3 !important;
    border-bottom: 1px solid $gray3 !important;
    border-radius: 0 !important;
    background-color: #8b9fc7 !important;
    color: #fff !important;
  }

  .ant-table-tbody .ant-table-row:hover .mouvement-hover {
    background-color: #fff !important;
    color: #0068ac !important;
    .mouvement-hover-text {
      color: #0189e3 !important;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td .hover-text {
    color: #fff !important;
  }
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #808080;
  font-weight: 500;
}

.table-wrapper
  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  border-right: 2px solid $primary;
  justify-content: center;
}

.mtooltip {
  color: #808080;
  background-color: white;
  align-items: center;
  .header-tooltip {
    border-bottom: 1px solid #ebebeb;
    margin-top: 11px;
    p {
      font-weight: 700;
      font-size: 14px;
      color: #505050;
      margin-bottom: 6px !important;
    }
  }
  .item {
    border-bottom: 1px solid #ebebeb;
    height: 25px;
    font-weight: 500;
    font-size: 13px;
    color: #808080;
    .item-child {
      width: 450px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .first-span {
        font-weight: 600;
        font-size: 14px;
        color: #505050;
        width: 30px;
        justify-content: flex-start;
      }
      .last-span {
        padding-left: 5px;
        width: 400px;
        justify-content: flex-start;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .s-item {
    border-bottom: none;
    align-items: center;
    .item-child {
      width: 350px;
      .first-span {
        font-weight: 600;
        font-size: 14px;
        color: #505050;
        width: 30px;
        justify-content: flex-start;
      }
      .last-span {
        width: 250px;
        padding-left: 5px;
        justify-content: flex-start;
      }
    }
  }
}

.m1-tooltip {
  color: #808080;
  background-color: white;
  align-items: center;
  border-radius: 0;
}

.ant-table-tbody > tr > td {
  text-overflow: ellipsis;
  overflow: hidden;
}

.movement-header-tooltip {
  position: relative;
  width: calc(100vw - 7.5rem);
  // height: 608px;
  border-radius: 0;
  // position: "absolute";
  left: -105px;
}
.statut-header-tooltip {
  position: relative;
}

@media only screen and (max-width: 1470px) {
  .mtooltip {
    .item {
      border-bottom: 1px solid #ebebeb;
      height: 20px;
      font-weight: 500;
      font-size: 11px;
      color: #808080;
      .item-child {
        width: 320px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .first-span {
          font-weight: 600;
          font-size: 15px;
          color: #505050;
          width: 30px;
          justify-content: flex-start;
        }
        .last-span {
          padding-left: 5px;
          width: 300px;
          justify-content: flex-start;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .s-item {
      border-bottom: none;
      align-items: center;
      .item-child {
        width: 260px;
        .first-span {
          font-weight: 600;
          font-size: 14px;
          color: #505050;
          width: 30px;
          justify-content: flex-start;
        }
        .last-span {
          padding-left: 5px;
          justify-content: flex-start;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .mtooltip {
    .item {
      border-bottom: 1px solid #ebebeb;
      height: 20px;
      font-weight: 500;
      font-size: 11px;
      color: #808080;
      .item-child {
        width: 245px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .first-span {
          font-weight: 600;
          font-size: 14px;
          color: #505050;
          width: 30px;
          justify-content: flex-start;
        }
        .last-span {
          padding-left: 5px;
          width: 250px;
          justify-content: flex-start;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .s-item {
      border-bottom: none;
      align-items: center;
      .item-child {
        width: 245px;
        .first-span {
          font-weight: 600;
          font-size: 14px;
          color: #505050;
          width: 30px;
          justify-content: flex-start;
        }
        .last-span {
          padding-left: 5px;
          justify-content: flex-start;
        }
      }
    }
  }
}

//PhotoView.tsx
.modal-preview.ant-modal .ant-modal-content {
  padding: 0;
}

//disabled link react router dom
.disabled-link {
  pointer-events: none;
}

.printer {
  content: url('../../../assets/icons/Blue-printer.svg');
  cursor: pointer;
}

.printer-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.download-icon::before {
  content: url('../../../assets/icons/Download.svg');
  margin-right: 10px;
}

.ant-table-column-sorters {
  justify-content: flex-start !important;
  .ant-table-column-title {
    flex: none !important;
    width: fit-content;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td .anticon {
  color: #fff;
}

.attendu-on-mission-info
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  line-height: 22px;
}

.attendu {
  .rc-virtual-list-scrollbar-vertical {
    position: fixed !important;
    z-index: 2 !important;
    top: 14rem !important;
    right: 1.25rem !important;
    width: 5px !important;
    visibility: visible !important;
    .rc-virtual-list-scrollbar-thumb {
      background-color: #0189e3 !important;
    }
  }
  .rc-virtual-list-scrollbar-horizontal {
    position: sticky !important;
    z-index: 2 !important;
    margin-top: 13px;
    height: 5px !important;
    visibility: visible !important;
    .rc-virtual-list-scrollbar-thumb {
      background-color: #0189e3 !important;
    }
  }
}

.attendu .ant-picker .ant-picker-input {
  height: 17px;
}

.attendu-on-mission-info {
  .ant-picker,
  .ant-select-selector {
    height: 24px !important;
  }
}

.custom-pagination
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: #fff !important;
  cursor: pointer;
}

#att_litige.signaler-disabled {
  color: $gray3;
  border-color: $gray3;
  cursor: not-allowed;
}
