.w-100p {
  width: 100%;
}

.w-30p {
  width: 30%;
}
.w-25p {
  width: 25%;
}
.w-5p{
  width: 5%;
}
.w-min-192{
  min-width: 192px;
}
.w-max-300{
  max-width: 300px;
}
.w-max-400{
  max-width: 300px;
}
.h-min-27{
  min-height: 27px;
}

.w-165 {
  width: 165px;
}
